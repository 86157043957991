import "common/mortgage/transactions/edit/index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import compose from "util/compose";
import { EVENT } from "constants/analytics";
import { segmentTrack } from "util/segment";
import TransactionDocumentUploader from "common/document/uploader/transaction_uploader";
import PointsOfContactSection from "common/mortgage/transactions/edit/sub_forms/points_of_contact_section";
import EnoteSection, {
  ENOTE_WARNING,
} from "common/mortgage/transactions/edit/sub_forms/enote_section";
import CustomerSignersSection, {
  validationRules as customerSignersValidationRules,
  asyncValidationRules as asyncCustomerSignersValidationRules,
} from "common/transactions/form/sub_forms/customer_signers";
import EmailForm from "common/mortgage/transactions/edit/sub_forms/special_instructions/email_form";
import NotaryNotesSection from "common/transactions/form/sub_forms/notary_notes";
import EditPayment from "common/transactions/form/sub_forms/payment";
import EditRecallReason, {
  validateRecallReason,
} from "common/mortgage/transactions/edit/recall_reason_modal";
import { PageFrameSmallWithPadding, PageFramePadding } from "common/page_frame";
import { TransactionCreationPageContainer } from "common/transaction_creation";
import { TransactionCreationPageHeader } from "common/transaction_creation/header";
import { TransactionCreationPageFooter } from "common/transaction_creation/footer";
import { TransactionCreationPageSection } from "common/transaction_creation/section";
import SigningTimeRestrictions, {
  validateSigningTimeRestrictions,
} from "common/mortgage/transactions/signing_time_restrictions";
import FooterSection from "common/transactions/form/footer";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import MortgageCloserAssignment, {
  validateCloserAssignment,
} from "common/mortgage/transactions/edit/sub_forms/closer_assignment";
import { DeprecatedFormRow } from "common/form/elements/row";
import OrderPlacedModal from "common/mortgage/transactions/edit/order_placed_modal";
import ConvertToHybridConfirmationModal from "lender_portal/transactions/edit/convert_to_hybrid_confirmation_modal";
import SendTransactionWhileProcessingModal from "common/transactions/send_transaction_while_processing_modal";
import EntityNameSection, {
  validationRules as entityNameValidationRules,
} from "common/mortgage/transactions/edit/sub_forms/entity_name_section";
import TemplateSplittingResults from "common/transactions/template_splitting_results";
import ClosingOpsOverrideModal from "common/mortgage/transactions/closing_ops_override_modal";
import { useFeatureFlag } from "common/feature_gating";
import { getFormValues, getFormErrors, composeValidators, composeAsyncValidators } from "util/form";
import { compact } from "util/object";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import AlertMessage from "common/core/alert_message";
import { scrollOnSubmitFail } from "util/scroll";
import { OrganizationTransactionCreationSource, Feature, ProcessingStates } from "graphql_globals";
import { SIGN_AHEAD } from "constants/feature_gates";
import {
  OTHER_COMMON_TRANSACTION_TYPES,
  MAX_SIGNERS,
  TRANSACTION_TYPE_OTHER,
  MORTGAGE_TRANSACTION_REQUIREMENTS,
} from "constants/transaction";
import useTitleAgencyLookup, {
  EmailAddressEventTypes,
} from "common/mortgage/transactions/edit/title_agency_service";
import { asyncValidationRules as asyncCollabValidationRules } from "common/mortgage/transactions/edit/sub_forms/points_of_contact_section/validate";
import { getRequirements } from "common/mortgage/transactions/requirements_service/service";
import { isRequired } from "util/requirements";
import { usePermissions } from "common/core/current_user_role";
import { TransactionCreationV3Banner } from "common/transaction_creation/v3/form/banner";
import { RecalledBanner } from "common/transaction_creation/v3/form/banner/recalled";

import { validationRules as collabValidationRules } from "./title_collaborator/validate";
import TitleCollaborator from "./title_collaborator";
import SendConfirmationModal, { ModalTypes } from "./send_confirmation_modal";
import TransactionDetailsSection, {
  validationRules as transactionDetailsValidationRules,
} from "./sub_forms/details";
import TransactionEditQuery from "./transaction_edit_query.graphql";

function validate(values, props) {
  return composeValidators(
    customerSignersValidationRules(values, props),
    validateSigningTimeRestrictions(values, props),
    transactionDetailsValidationRules(values, props),
    validateRecallReason(values),
    entityNameValidationRules(values, props),
    collabValidationRules(values, props),
    validateCloserAssignment,
  )(values);
}

function asyncValidate(values, props) {
  return composeAsyncValidators(
    asyncCollabValidationRules(values),
    asyncCustomerSignersValidationRules(values, props),
  )(values);
}

// Invalid Transaction Reasons

const notarizationRequired = (
  <FormattedMessage
    id="6c46db9c-9193-4506-999b-04e2fd91d937"
    defaultMessage="You must attach at least one document requiring notarization to continue."
  />
);

const oneDocumentRequired = (
  <FormattedMessage
    id="67ecb92f-140d-4d47-bfa6-babe17f50785"
    defaultMessage="You must attach at least one document to continue."
  />
);

const oneEsignDocumentRequired = (
  <FormattedMessage
    id="3e3d3b5e-bcfb-4177-857e-fc21a65f21f4"
    defaultMessage="You must attach at least one document that can be eSigned to continue."
  />
);

const missingRequiredInformation = (
  <FormattedMessage
    id="34cc5261-329e-43e0-977e-50c8f8cc3f78"
    defaultMessage="Please enter the required information in the rest of the form before modifying this section."
  />
);

const noop = () => {};

function documentBundleHasDocuments(documentBundle) {
  const filteredDocuments = documentBundle.documents.edges.filter(
    ({ node }) => !node.is_consent_form,
  );
  return filteredDocuments.length > 0;
}

function documentBundleHasHybridDocumentsToSign(documents) {
  return documents.some((doc) => !doc.signingRequiresMeeting && !doc.isConsentForm);
}

function hasNotarizationRequiredDocuments(documents) {
  return documents.some((doc) => doc.notarizationRequired);
}

function convertDocumentsFromEdgesToNodes(documents) {
  return documents.edges.map(({ node }) => ({
    notarizationRequired: node.notarization_required,
    customerCanAnnotate: node.customer_can_annotate,
    witnessRequired: node.witness_required,
    signingRequiresMeeting: node.signing_requires_meeting,
    isConsentForm: node.is_consent_form,
  }));
}

class TransactionEditForm extends PureComponent {
  constructor(props) {
    super(props);

    const { initialize, initialData, change, transaction } = props;
    this.state = {
      showPaymentModal: false,
      showRecallModal: false,
      showConvertToHybridModal: false,
      annotateSaveError: null,
      enoteWarning: null,
      showOrderPlacedModal: false,
      showTemplateSplittingResults: false,
      activeSendModalType: null,
      closingOpsOverrideMessage: null,
      showInProgressModal: false,
    };

    // Adding 'change' reduxForm prop to initial values so that it can be used in container component
    initialize(
      compact({
        ...initialData,
        change,
        EditTransactionPropertyAddress: {
          line1: transaction?.propertyAddress?.line1,
          line2: transaction?.propertyAddress?.line2,
          city: transaction?.propertyAddress?.city,
          country: transaction?.propertyAddress?.country || "US", // default country to US because we need a US address
          state: transaction?.propertyAddress?.state,
          postal: transaction?.propertyAddress?.postal,
        },
      }),
      {
        keepValues: true, // prevents values initialized elsewhere from being cleared
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { titleAgentLookup, change, hasCollaboratorSection } = this.props;
    if (prevState.enoteWarning !== this.state.enoteWarning) {
      scrollOnSubmitFail();
    }
    if (
      hasCollaboratorSection &&
      prevProps.titleAgentLookup !== titleAgentLookup &&
      titleAgentLookup?.type === EmailAddressEventTypes.CHANGE // we don't want to change form values on mount due to lookup result
    ) {
      // A bit of a hack to provide validation code with the data it needs
      // to determine which validation to run depending on whether an email
      // is onboarded or not.
      change("titleAgentLookup", titleAgentLookup);
      if (titleAgentLookup && !titleAgentLookup.error) {
        change(`pointsOfContact[${titleAgentLookup.index}].firstName`, titleAgentLookup.firstName);
        change(`pointsOfContact[${titleAgentLookup.index}].lastName`, titleAgentLookup.lastName);
        change(
          `pointsOfContact[${titleAgentLookup.index}].phoneNumber`,
          titleAgentLookup.phoneNumber,
        );
        change(`pointsOfContact[${titleAgentLookup.index}].organizationName`, null);
        change(`pointsOfContact[${titleAgentLookup.index}].organizationAddress`, null);

        change("titleAgency", titleAgentLookup.titleAgencyId);
      }
    }
  }

  componentWillUnmount() {
    // Must destroy redux-form manually due to destroyOnUnmount prop
    // destroyOnUnmount used to get around validation issue caused by redux form field
    // unmounting in one component and re-mounting in another. ex. Schedule Window in txn edit page
    this.props.destroy();
  }

  closePaymentModal = () => {
    this.setState({ showPaymentModal: false });
  };

  closeRecallModal = () => {
    this.setState({ showRecallModal: false });
  };

  closeConvertToHybridModal = () => {
    this.setState({ showConvertToHybridModal: false });
  };

  openConvertToHybridModal = () => {
    this.setState({ showConvertToHybridModal: true });
  };

  handleConfirmConvertToHybrid = (transactionType, witnesses) => {
    this.props.change("transactionType", transactionType);
    this.props.change("organizationTransactionWitnesses", witnesses);
    this.setState({ showConvertToHybridModal: false });
  };

  setDocumentError = (errorText) => {
    const annotateSaveError = (
      <DeprecatedFormRow
        className="RealEstateDocumentUploaderAnnotateError validation-failed"
        data-automation-id="document-uploader-error"
      >
        {errorText}
      </DeprecatedFormRow>
    );
    this.setState({ annotateSaveError });
    scrollOnSubmitFail();
  };

  setEnoteWarning = (enoteWarning) => {
    this.setState({ enoteWarning });
    scrollOnSubmitFail();
  };

  handleConfirmSend = (type) => {
    if (type === ModalTypes.SIGNER) {
      this.forceSendToSigner = true;
    } else {
      this.forceSendToSigner = false;
    }
    this.handleSendModalClose();
    this.handleSend();
  };

  handleSend = ({ requireRecallMessage = true, closingOpsOverride = false } = {}) => {
    const { transaction, handleSubmit, showPlaceOrderUI, organization } = this.props;
    const { forceSendToSigner } = this;

    if (!organization.paymentSpecified) {
      this.setState({ showPaymentModal: true });
    } else if (transaction.isRecalled && requireRecallMessage) {
      this.setState({ showRecallModal: true });
    } else if (showPlaceOrderUI) {
      handleSubmit(this.validateCanPlaceOrder)();
    } else {
      handleSubmit(this.checkSendValidityAndSend({ forceSendToSigner, closingOpsOverride }))();
    }
  };

  handleSave = (withExit = true) => {
    const { onSaveAndClose, onSave, handleSubmit, invalid: reduxFormInvalid } = this.props;
    if (reduxFormInvalid) {
      this.setDocumentError(missingRequiredInformation);
    }
    return withExit ? handleSubmit(onSaveAndClose)() : handleSubmit(onSave)();
  };

  handleSendModalTypeChange = (newSendModalType) => {
    const { valid, handleSubmit } = this.props;
    if (valid) {
      this.setState({
        activeSendModalType: newSendModalType,
      });
    } else {
      handleSubmit(noop)();
      scrollOnSubmitFail();
    }
  };

  handleSendModalClose = () => {
    this.setState({
      activeSendModalType: null,
    });
  };

  checkForDocumentProcessing = () => {
    const documentsProcessing = this.props.transaction.document_bundle.documents.edges.some(
      (edge) => edge.node.processing_state === ProcessingStates.PENDING,
    );
    if (documentsProcessing) {
      this.setState({ showInProgressModal: true });
    } else {
      this.forceSendToSigner = false;
      this.handleSend();
    }
  };

  eNoteRequired = () => {
    const {
      transaction: { transactionType },
    } = this.props;
    const transactionRequirements = getRequirements(transactionType, "lender");
    return isRequired(transactionRequirements[MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]);
  };

  /**
   * Check if we can send the transaction and then send if possible.
   * If the transaction passes all form validation, then we check document requirements. Document
   * requirements are not defined in the form so we have to check them manually here. This
   * is a list of why a transaction might not send (unrelated to form validation):
   * - If no documents have been marked as "requires notarization"
   * - If the user has no documents have been marked as "notarization required"
   * - If the transaction is hybrid and there are zero signable documents attached
   */
  checkSendValidityAndSend = ({ forceSendToSigner = false, closingOpsOverride = false } = {}) => {
    return (formValues) => {
      const { disabledSubmit, transaction, onSend, showEnoteSection } = this.props;
      const { requiresNsaMeeting, document_bundle } = transaction;

      const documents = convertDocumentsFromEdgesToNodes(document_bundle.documents);
      const enoteDocument = transaction.document_bundle.documents.edges.find((document) => {
        return document.node.isEnote;
      });
      const satisfiedEnoteRequirement =
        transaction.paperNoteConsent || document_bundle.includesEnote || !this.eNoteRequired();

      if (showEnoteSection && !satisfiedEnoteRequirement) {
        if (document_bundle.enoteSeed) {
          this.setEnoteWarning(ENOTE_WARNING.INCOMPLETE);
        } else {
          this.setEnoteWarning(ENOTE_WARNING.MISSING);
        }
        return;
      } else if (
        showEnoteSection &&
        document_bundle.includesEnote &&
        (enoteDocument?.node?.mortgageBorrowers?.edges || []).some(
          (mortgageBorrower) => !mortgageBorrower.node.hasSsn,
        )
      ) {
        this.setEnoteWarning(ENOTE_WARNING.SSN_MISSING);
        return;
      }
      this.setState({ enoteWarning: null });

      if (!requiresNsaMeeting) {
        if (disabledSubmit || !documentBundleHasHybridDocumentsToSign(documents)) {
          this.setDocumentError(oneEsignDocumentRequired);
          return;
        }
      } else if (disabledSubmit || !hasNotarizationRequiredDocuments(documents)) {
        this.setDocumentError(notarizationRequired);
        return;
      }
      this.setState({ annotateSaveError: null });
      onSend(formValues, { forceSendToSigner, closingOpsOverride }).then((result) => {
        if (result?.closingOpsOverrideMessage) {
          this.setState({
            closingOpsOverrideMessage: result.closingOpsOverrideMessage,
          });
        }
      });
    };
  };

  validateCanPlaceOrder = () => {
    const { onSend, formValues, transaction } = this.props;
    if (!documentBundleHasDocuments(transaction.document_bundle)) {
      this.setDocumentError(oneDocumentRequired);
      return;
    }

    onSend(formValues).then(() => {
      this.setState({
        showOrderPlacedModal: true,
      });
    });
  };

  handleOpenAnnotationModal = () => {
    const { onSave, handleSubmit, invalid: reduxFormInvalid, formValues } = this.props;

    // if transaction isn't ready to be annotated, we need to return a
    // rejected promise to TransactionDocumentUploader. We render the
    // error message in this component.
    handleSubmit(noop)(); // we submit the form to force a validation check on the form to find any errors
    if (reduxFormInvalid) {
      this.setDocumentError(missingRequiredInformation);
      return Promise.reject(new Error("Invalid form, don't open"));
    }
    return onSave(formValues);
  };

  checkCanOpenAddDocumentModal = () => {
    const { onSave, handleSubmit, invalid: reduxFormInvalid, formValues } = this.props;
    handleSubmit(noop)(); // we submit the form to force a validation check on the form to find any errors
    if (reduxFormInvalid) {
      const errorMessage = (
        <FormattedMessage
          id="c1c1faa0-9bbe-429c-86ee-8c72fec109d8"
          defaultMessage="Please enter the required information in the rest of the form before uploading a document"
        />
      );
      this.setDocumentError(errorMessage);
      return Promise.reject(new Error("Invalid form, don't open"));
    }
    return onSave(formValues);
  };

  handleCloseAnnotationModal = () => {
    const {
      transaction: { id },
    } = this.props;
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ANNOTATE_DOCUMENTS_FINISHED, {
      organization_transaction_id: id,
    });
  };

  checkCanAddEnote = () => {
    const { handleSubmit, onSave, invalid: reduxFormInvalid, formValues } = this.props;
    handleSubmit(noop)(); // we submit the form to force a validation check on the form to find any errors
    if (reduxFormInvalid) {
      return { valid: false };
    }
    return onSave(formValues)
      .then(() => {
        return { valid: true };
      })
      .catch(() => {
        return { valid: false };
      });
  };

  // Form Validation

  /**
   * Check if a document is eligible for sign ahead. To return true, the document
   * would have to not require notarization, not be able to be
   * annotated by a customer in prefill, and not require a witness.
   */
  docIsSignAheadEligible = (doc) =>
    !doc.notarizationRequired && !doc.customerCanAnnotate && !doc.witnessRequired;

  renderEnoteSection = () => {
    const {
      onUpdatePaperNoteConsent,
      onAddEnoteToTransaction,
      onRemoveDocumentFromTransaction,
      onUpdateMortgageBorrower,
      onRemoveEnoteSeed,
      onSaveEnoteSeed,
      onInitializeEnoteSeed,
      enoteMutationLoading,
      transaction: {
        document_bundle: { documents, enoteSeed },
        paperNoteConsent,
      },
    } = this.props;
    const { enoteWarning } = this.state;
    const enoteDocument = documents.edges.find((document) => {
      return document.node.isEnote;
    });
    return (
      <TransactionCreationPageSection
        iconName="blank-doc"
        title={
          <FormattedMessage
            id="94e7d795-0f53-4fd7-9d8f-2be3b4e68a4a"
            defaultMessage="Promissory note"
          />
        }
      >
        <EnoteSection
          onUpdatePaperNoteConsent={onUpdatePaperNoteConsent}
          paperNoteConsent={paperNoteConsent}
          enoteMutationLoading={enoteMutationLoading}
          onAddEnoteToTransaction={onAddEnoteToTransaction}
          onRemoveDocumentFromTransaction={onRemoveDocumentFromTransaction}
          onUpdateMortgageBorrower={onUpdateMortgageBorrower}
          onRemoveEnoteSeed={onRemoveEnoteSeed}
          onSaveEnoteSeed={onSaveEnoteSeed}
          onInitializeEnoteSeed={onInitializeEnoteSeed}
          enoteDocument={enoteDocument?.node}
          enoteWarning={enoteWarning}
          enoteSeed={enoteSeed}
          checkCanAddEnote={this.checkCanAddEnote}
          isRequired={this.eNoteRequired()}
        />
      </TransactionCreationPageSection>
    );
  };

  onReturnToDashboard = () => {
    this.props.navigate("/");
  };

  getAdditionalActions = () => {
    const { showSendToSignerButton } = this.props;

    if (showSendToSignerButton) {
      return [
        {
          text: (
            <FormattedMessage
              id="5fccbd29-5fad-46f6-9843-79c72d97e7fb"
              defaultMessage="Send to Signer"
            />
          ),
          action: this.handleSendModalTypeChange.bind(this, ModalTypes.SIGNER),
          key: "sendToSigner",
        },
        {
          text: (
            <FormattedMessage
              id="6d15571c-bf0d-4a4c-8f54-e92df0c40be2"
              defaultMessage="Send To Title Agency"
            />
          ),
          action: this.handleSendModalTypeChange.bind(this, ModalTypes.TITLE),
          key: "sendToTitle",
        },
      ];
    }
    return null;
  };

  confirmClosingOpsOverride = () => {
    this.setState({ closingOpsOverrideMessage: null });
    // handleSend prompts for recall message first and then closing ops, so we can assume
    // requireRecallMessage to be false
    this.handleSend({ requireRecallMessage: false, closingOpsOverride: true });
  };

  render() {
    const {
      transaction,
      viewer,
      organization,
      formErrors,
      formValues,
      form,
      disabledSubmit,
      showPlaceOrderUI,
      initialData,
      onDeleteCustomerSigner,
      showEnoteSection,
      sendLabel,
      onDeleteNotaryInstruction,
      onUpdateNotaryInstruction,
      usersOrgCreatedTransaction,
      titleAgentLookup,
      onEmailChange,
      change,
      hasCollaboratorSection,
      isFullRON,
      canSendToSigner,
      signAheadEnabled,
      llcTransactionsEnabled,
      permissions: { hasPermissionFor },
    } = this.props;

    const {
      requiresNsaMeeting,
      transactionType,
      document_bundle,
      creationSource,
      templateSplittingResults,
      orderProgress,
      id: transactionId,
      duplicatedTransaction,
    } = transaction;
    const { featureList } = organization;
    const documents = convertDocumentsFromEdgesToNodes(document_bundle.documents);
    const {
      showPaymentModal,
      showRecallModal,
      showConvertToHybridModal,
      annotateSaveError,
      showOrderPlacedModal,
      showTemplateSplittingResults,
      activeSendModalType,
      closingOpsOverrideMessage,
      showInProgressModal,
    } = this.state;
    const showCustomEmails = featureList.includes(Feature.CUSTOM_EMAILS);
    const isLLCTransaction =
      llcTransactionsEnabled || creationSource === OrganizationTransactionCreationSource.RESWARE;
    const isTransactionForEntity =
      formValues.isTransactionForEntity === undefined
        ? initialData.isTransactionForEntity
        : formValues.isTransactionForEntity;
    const canDuplicate =
      hasPermissionFor("duplicateTransaction") &&
      featureList.includes(Feature.DUPLICATE_TRANSACTIONS);

    const sendDisabledReason = duplicatedTransaction
      ? "Duplicate transactions cannot be sent."
      : null;
    const defaultDocPermissions = Object.freeze({
      witnessRequired: false,
    });
    let defaultDocRequirements;

    if (!requiresNsaMeeting) {
      defaultDocRequirements = Object.freeze({
        esign: false,
        notarizationRequired: false,
        proofingRequired: false,
        signingRequiresMeeting: true,
      });
    } else if (hasPermissionFor("manageOpenOrders") || showPlaceOrderUI) {
      defaultDocRequirements = Object.freeze({
        esign: false,
        notarizationRequired: false,
        proofingRequired: false,
        signingRequiresMeeting: false,
      });
    }

    const showDocumentError =
      (showPlaceOrderUI && !documentBundleHasDocuments(document_bundle)) ||
      (!requiresNsaMeeting && !documentBundleHasHybridDocumentsToSign(documents)) ||
      (requiresNsaMeeting && !hasNotarizationRequiredDocuments(documents));
    return (
      <div className="TransactionEditForm">
        <TransactionCreationV3Banner
          transactionId={transaction.id}
          userId={viewer.user.id}
          save={this.props.dirty ? () => this.handleSave(false) : false}
          optedOut
        />

        {!hasPermissionFor("editOrganizationTransactions") && (
          <AlertMessage kind="warning">
            <FormattedMessage
              id="f5252594-641b-4c7e-8ef1-19357b6e54d7"
              defaultMessage="You do not have permission to edit or send this transaction."
            />
          </AlertMessage>
        )}
        {showPaymentModal && (
          <EditPayment onCancel={this.closePaymentModal} onComplete={this.handleSend} />
        )}
        {showRecallModal && (
          <EditRecallReason
            formName="EditTransaction"
            formErrors={formErrors}
            onClose={this.closeRecallModal}
            onComplete={this.handleSend}
          />
        )}
        {showOrderPlacedModal && <OrderPlacedModal onClose={this.onReturnToDashboard} />}
        {showConvertToHybridModal && (
          <ConvertToHybridConfirmationModal
            transactionId={transactionId}
            onConvert={this.handleConfirmConvertToHybrid}
            onCancel={this.closeConvertToHybridModal}
          />
        )}
        {transaction.duplicatedTransaction && (
          <AlertMessage kind="warning" data-automation-id="duplicate-transaction-banner" centerText>
            <FormattedMessage
              id="e824712c-6f4a-43af-b439-bdcba85f57ad"
              defaultMessage="This is a duplicate transaction."
            />
          </AlertMessage>
        )}
        <PageFrameSmallWithPadding>
          <div className="TransactionEditForm--content">
            <TransactionCreationPageContainer>
              <TransactionCreationPageHeader
                transactionType={transactionType}
                requiresNsaMeeting={requiresNsaMeeting}
              />
              <TransactionCreationPageSection
                iconName="annotation-line"
                title={
                  <FormattedMessage
                    id="84b99d39-bf02-463e-b50e-f087cbc7ad2e"
                    defaultMessage="Transaction details"
                  />
                }
              >
                <TransactionDetailsSection
                  formName="EditTransaction"
                  autofocusName={!initialData.transactionName}
                  transaction={transaction}
                  transactionType={transactionType}
                  readOnly={!usersOrgCreatedTransaction && !hasPermissionFor("manageOpenOrders")}
                  onConvertToHybrid={this.openConvertToHybridModal}
                  canSendToSigner={canSendToSigner}
                />
              </TransactionCreationPageSection>
              <TransactionCreationPageSection
                iconName="work-outline"
                title={
                  OTHER_COMMON_TRANSACTION_TYPES.includes(transactionType) ? (
                    <FormattedMessage
                      id="2d1eb3c5-3bd7-4031-8311-1636dac1fbcb"
                      defaultMessage="Points of contact"
                    />
                  ) : (
                    <FormattedMessage
                      id="4f219dd2-bde8-4b63-853e-81c23d9ae130"
                      defaultMessage="Closing team"
                    />
                  )
                }
                subtitle={
                  OTHER_COMMON_TRANSACTION_TYPES.includes(transactionType) ? (
                    <FormattedMessage
                      id="a70e2242-45c4-4cc5-8c96-6476a40dbec6"
                      defaultMessage="Points of contact are any people who would have relevant information for the signing (i.e. Mortgage broker, loan officer, title agent, or realtor). The signer will be able to call them in-meeting."
                    />
                  ) : (
                    <FormattedMessage
                      id="6e3a45c3-bdd0-46e8-b075-c4aa6463622b"
                      defaultMessage="The closing team includes anyone who will be supporting the transaction. You can give closing team members permission to view and download documents, as well as show their contact information to the signers so that they may be contacted during document review or dialed into the closing."
                    />
                  )
                }
              >
                {hasCollaboratorSection ? (
                  <PointsOfContactSection
                    formName="EditTransaction"
                    transactionType={transactionType}
                    organization={organization}
                    hasCollaboratorSection
                    customContactFilter={(index) => {
                      return index === 0;
                    }}
                    renderCustomContact={(member, pointOfContact) => {
                      return (
                        <TitleCollaborator
                          key={member}
                          onEmailChange={(val, index) => {
                            if (!val?.trim()) {
                              // clear poc values related to creating single seat org
                              // since they may just want to provide a title agency.
                              change(`${member}.organizationAddress`, null);
                              change(`${member}.organizationName`, null);
                            }
                            onEmailChange(val, index);
                          }}
                          titleAgentLookup={titleAgentLookup}
                          fieldNamePrefix={member}
                          pointOfContact={pointOfContact}
                          isFullRON={isFullRON}
                          isOtherTransactionType={transactionType === TRANSACTION_TYPE_OTHER}
                          index={pointOfContact?.index}
                          initialTitleAgency={transaction.titleAgency}
                          titleAgency={formValues.titleAgency}
                          onClickRemove={pointOfContact?.onClickRemove}
                          onFieldChange={change}
                          recordingLocation={transaction.recordingLocation}
                          organization={organization}
                        />
                      );
                    }}
                    publicOrganizationFeatureFlags={transaction.organization.featureFlags}
                  />
                ) : (
                  <PointsOfContactSection
                    formName="EditTransaction"
                    transactionType={transactionType}
                    organization={organization}
                    publicOrganizationFeatureFlags={transaction.organization.featureFlags}
                  />
                )}
              </TransactionCreationPageSection>
              <TransactionCreationPageSection
                iconName="employees"
                title={
                  <FormattedMessage
                    id="2ef5dad6-a665-4468-b8cd-9397da949264"
                    defaultMessage="Signer details"
                  />
                }
              >
                {isLLCTransaction && <EntityNameSection formName={form} />}
                <CustomerSignersSection
                  formName={form}
                  organization={organization}
                  onDeleteCustomerSigner={onDeleteCustomerSigner}
                  maxSigners={MAX_SIGNERS}
                  hideSecondaryId={!requiresNsaMeeting}
                  readOnly={!usersOrgCreatedTransaction && !hasPermissionFor("manageOpenOrders")}
                  showSignatoryCapacity={isTransactionForEntity}
                  showVestingTypeDropdown
                  isHybrid={isHybridTransactionType(transactionType)}
                />
              </TransactionCreationPageSection>
              <TransactionCreationPageSection
                iconName="calendar"
                title={
                  OTHER_COMMON_TRANSACTION_TYPES.includes(transactionType) ? (
                    <FormattedMessage
                      id="1db04a4e-abbf-4eeb-b929-697f60bcac71"
                      defaultMessage="Signing schedule"
                    />
                  ) : (
                    <FormattedMessage
                      id="48b06fdb-4ad4-4fa5-84ab-c343c4c0afb1"
                      defaultMessage="Closing schedule"
                    />
                  )
                }
              >
                <SigningTimeRestrictions
                  formName="EditTransaction"
                  transaction={transaction}
                  usersOrgCreatedTransaction={usersOrgCreatedTransaction}
                />
                {featureList.includes(Feature.ORGANIZATION_NOTARIES) && requiresNsaMeeting && (
                  <MortgageCloserAssignment
                    formName="EditTransaction"
                    organization={organization}
                    lenderOrgId={organization.id}
                    titleUnderwriterOrgId={formValues.titleUnderwriter}
                    usStateId={transaction.recordingLocation?.usState.id}
                    featureFlags={transaction.organization.featureFlags}
                    notaryAssigneeId={transaction.closer?.id}
                    isNotarizingOrg={transaction.notaryOrganization?.id === organization.id}
                  />
                )}
              </TransactionCreationPageSection>
              {showEnoteSection && this.renderEnoteSection()}
              <TransactionCreationPageSection
                iconName="document-doc"
                title={
                  <FormattedMessage
                    id="e122017d-7432-49c0-9d0e-fb8fd9e0cadd"
                    defaultMessage="Document upload"
                  />
                }
              >
                {showDocumentError && annotateSaveError}
                {hasPermissionFor("viewSplittingResults") &&
                  templateSplittingResults?.length > 0 && (
                    <DeprecatedFormRow className="RealEstateDocumentSplittingResults">
                      <span
                        onClick={() => {
                          this.setState({ showTemplateSplittingResults: true });
                        }}
                        data-automation-id="show-template-splitting-results"
                      >
                        <FormattedMessage
                          id="4b38e7fe-621a-45e0-94cc-ef86c66d919b"
                          defaultMessage="Click here to view the splitting results."
                        />
                      </span>
                    </DeprecatedFormRow>
                  )}
                {showTemplateSplittingResults && (
                  <TemplateSplittingResults
                    onClose={() => {
                      this.setState({ showTemplateSplittingResults: false });
                    }}
                    templateSplittingResults={templateSplittingResults}
                  />
                )}
                <SubForm>
                  <SubFormSection fullWidth>
                    <DeprecatedFormRow noMargin>
                      <TransactionDocumentUploader
                        transaction={transaction}
                        organization={organization}
                        viewer={viewer}
                        canRequireProofing={false}
                        onOpenAnnotateModal={this.handleOpenAnnotationModal}
                        checkCanOpenAddDocumentModal={this.checkCanOpenAddDocumentModal}
                        canRequireMeeting={signAheadEnabled}
                        defaultDocRequirements={defaultDocRequirements}
                        defaultDocPermissions={defaultDocPermissions}
                        canSetDocRequirements={!showPlaceOrderUI}
                        canSetDocPermissions={!showPlaceOrderUI}
                        openAnnotateModalAfterDocumentsUploaded={!showPlaceOrderUI}
                        readOnly={showPlaceOrderUI}
                        cannotEditDocs={!hasPermissionFor("editOrganizationTransactions")}
                        disableTemplateUpload={!(organization.templates.totalCount > 0)}
                        allowDownload={hasPermissionFor("viewDownloadOriginalPdf")}
                        transactionQuery={TransactionEditQuery}
                      />
                    </DeprecatedFormRow>
                  </SubFormSection>
                </SubForm>
              </TransactionCreationPageSection>
              <TransactionCreationPageSection
                iconName="email"
                title={
                  <FormattedMessage
                    id="147ff83d-008d-402a-9737-25eaf79592a9"
                    defaultMessage="Custom email"
                  />
                }
              >
                {showCustomEmails && <EmailForm formName="EditTransaction" />}
              </TransactionCreationPageSection>
              <TransactionCreationPageSection
                iconName="blank-doc"
                title={
                  <FormattedMessage
                    id="54ff6f26-ea27-4a1c-ae92-f10d25284f74"
                    defaultMessage="Notary notes"
                  />
                }
              >
                <NotaryNotesSection
                  formName="EditTransaction"
                  instructions={transaction.document_bundle.instructions}
                  onUpdateNotaryInstruction={onUpdateNotaryInstruction}
                  onDeleteNotaryInstruction={onDeleteNotaryInstruction}
                />
              </TransactionCreationPageSection>
            </TransactionCreationPageContainer>
          </div>
        </PageFrameSmallWithPadding>
        <TransactionCreationPageFooter
          warningMessage={<RecalledBanner transaction={transaction} />}
        >
          <PageFramePadding className="TransactionEditForm--footer">
            <FooterSection
              isLoading={disabledSubmit}
              onSend={() => this.checkForDocumentProcessing()}
              onSave={this.handleSave}
              sendLabel={sendLabel}
              showPlaceOrderUI={showPlaceOrderUI}
              additionalActions={this.getAdditionalActions()}
              sendDisabledReason={sendDisabledReason}
              transactionId={transactionId}
              orderProgress={orderProgress}
              canDuplicate={canDuplicate}
              availableTransactionLabels={organization.availableTransactionLabels}
            />
          </PageFramePadding>
        </TransactionCreationPageFooter>
        {activeSendModalType && (
          <SendConfirmationModal
            onSend={this.handleConfirmSend}
            onCancel={this.handleSendModalClose}
            activeSendModalType={activeSendModalType}
          />
        )}
        {closingOpsOverrideMessage && (
          <ClosingOpsOverrideModal
            onConfirm={this.confirmClosingOpsOverride}
            onCancel={() => {
              this.setState({ closingOpsOverrideMessage: null });
            }}
            closingOpsOverrideMessage={closingOpsOverrideMessage}
          />
        )}
        {showInProgressModal && (
          <SendTransactionWhileProcessingModal
            onSend={() => {
              this.setState({ showInProgressModal: false }, () => {
                this.forceSendToSigner = false;
                this.handleSend();
              });
            }}
            onCancel={() => {
              this.setState({ showInProgressModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

TransactionEditForm.propTypes = {
  onSaveAndClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool,
  permissions: PropTypes.object.isRequired,
  transaction: PropTypes.shape({
    orderProgress: PropTypes.shape({
      label: PropTypes.string,
    }),
    document_bundle: PropTypes.shape({
      includesEnote: PropTypes.bool,
      document: PropTypes.arrayOf(
        PropTypes.shape({
          edges: PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              notarization_required: PropTypes.bool,
              customer_can_annotate: PropTypes.bool,
              witness_required: PropTypes.bool,
              signing_requires_meeting: PropTypes.bool,
              is_consent_form: PropTypes.bool,
              hidden: PropTypes.bool,
              isEnote: PropTypes.bool,
              s3OriginalAsset: PropTypes.shape({
                url: PropTypes.string,
              }),
              mortgageBorrowers: PropTypes.shape({
                edges: PropTypes.arrayOf(
                  PropTypes.shape({
                    node: PropTypes.shape({
                      hasSsn: PropTypes.bool,
                    }),
                  }),
                ),
              }),
            }),
          }),
        }),
      ),
    }),
    requiresNsaMeeting: PropTypes.bool,
    creationSource: PropTypes.oneOf(Object.values(OrganizationTransactionCreationSource))
      .isRequired,
    organization: PropTypes.shape({
      featureList: PropTypes.array,
      name: PropTypes.string,
      paymentSpecified: PropTypes.bool,
    }).isRequired,
    paperNoteConsent: PropTypes.bool,
    templateSplittingResults: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        successful: PropTypes.bool,
        results: PropTypes.array,
        s3OriginalAsset: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    titleAgencyAccess: PropTypes.bool.isRequired,
  }).isRequired,
  /** The initial data to put in the form */
  initialData: PropTypes.shape({
    transactionName: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func,
  onDeleteCustomerSigner: PropTypes.func.isRequired,
  showEnoteSection: PropTypes.bool,
  sendLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showSendToSignerButton: PropTypes.bool,
  onUpdateNotaryInstruction: PropTypes.func,
  onDeleteNotaryInstruction: PropTypes.func,
  usersOrgCreatedTransaction: PropTypes.bool.isRequired,
  showPlaceOrderUI: PropTypes.bool,
  canSendToSigner: PropTypes.bool,

  // Lender only
  enoteMutationLoading: PropTypes.bool,
  onUpdatePaperNoteConsent: PropTypes.func,
  onAddEnoteToTransaction: PropTypes.func,
  onRemoveDocumentFromTransaction: PropTypes.func,
  onUpdateMortgageBorrower: PropTypes.func,
  onRemoveEnoteSeed: PropTypes.func,
  onSaveEnoteSeed: PropTypes.func,
  onInitializeEnoteSeed: PropTypes.func,
};

TransactionEditForm.defaultProps = {
  onUpdatePaperNoteConsent: noop,
  onAddEnoteToTransaction: noop,
  onRemoveDocumentFromTransaction: noop,
  onUpdateMortgageBorrower: noop,
  onRemoveEnoteSeed: noop,
  onSaveEnoteSeed: noop,
  onInitializeEnoteSeed: noop,
  showSendToSignerButton: false,
  onEmailChange: () => {},
};

let saveResolver;
const TransactionEditReduxForm = compose(
  (Component) => (props) => (
    <Component
      {...props}
      navigate={useNavigate()}
      signAheadEnabled={useFeatureFlag(SIGN_AHEAD)}
      llcTransactionsEnabled={useFeatureFlag("llc_transactions")}
      isRealEstateCollabEnabled={Boolean(props.organization.realEstateCollabEnabled)}
      permissions={usePermissions()}
    />
  ),
  reduxForm({
    form: "EditTransaction",
    validate,
    asyncValidate,
    asyncBlurFields: ["customerSigners[].email", "pointsOfContact[].email"],
    onSubmitFail: () => {
      saveResolver?.("failed");
      scrollOnSubmitFail();
    },
    onSubmitSuccess: () => {
      saveResolver?.("saved");
    },
    destroyOnUnmount: false,
  }),
  getFormValues("EditTransaction"),
  getFormErrors("EditTransaction"),
)((props) => {
  const handleSubmit = (onSubmit) => {
    return () => {
      return new Promise((resolve) => {
        saveResolver = resolve;
        props.handleSubmit(onSubmit)();
      });
    };
  };
  return <TransactionEditForm {...props} handleSubmit={handleSubmit} />;
});

const CollabTransactionEditForm = (props) => {
  const primaryCollaborator = props.initialData.pointsOfContact[0];
  const { onEmailChange, titleAgentLookup, titleAgentLookupLoading } = useTitleAgencyLookup(
    primaryCollaborator?.email,
  );

  return (
    <TransactionEditReduxForm
      {...props}
      disabledSubmit={props.disabledSubmit || titleAgentLookupLoading}
      onEmailChange={onEmailChange}
      titleAgentLookup={titleAgentLookup}
    />
  );
};

const TransactionEditFormWrapper = (props) => {
  if (props.hasCollaboratorSection) {
    return <CollabTransactionEditForm {...props} />;
  }
  return <TransactionEditReduxForm {...props} />;
};

export default TransactionEditFormWrapper;
